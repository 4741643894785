<template>
  <div class="footer mt-5">
    <div class="row bg-dark text-secondary p-5 pb-1">
      <div class="col-md-4 about-me">
        <h4 class="text-secondary">
          <i class="fa fa-id-badge me-1" aria-hidden="true"></i>About Me
        </h4>
        <p>Ing.Ahmed Abul Basher</p>
        <p>Dammannstr.62</p>
        <p>45138 Essen</p>
      </div>
      <div class="col-md-4">
        <h4><i class="fa fa-phone-square" aria-hidden="true"></i>Contact Me</h4>
        <p>ahmedabulbasher@hotmail.com</p>
        <p>+4915735568378</p>
      </div>
      <div class="col-md-4">
        <h4><i class="fa fa-graduation-cap" aria-hidden="true"></i>Qualifikation</h4>
        <p>Software Engineering <small>Back- & Front-Entwickler</small></p>
        <p>Elektrotechnik <small>Schwerpunkt Automatisierungstechnik</small></p>
      </div>
      <div class="line"></div>
      <small class="text-center">
        @ In diesem Projekt wurde folgende Teschnelogien verwendet: HTML5, CSS3,
        Bootstrab5, VueJs2, Axios, Json, Symfony7 und MYSQL
      </small>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopmeLaravelVuejsFooter",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="scss" scoped>
.footer {
  p {
    margin-bottom: 0px;
  }
  h4 {
    border-bottom: 1px solid;
    letter-spacing: 3px;
  }
  .col-md-4 {
    border-left: 1px solid;
  }
  .line {
    height: 1px;
    width: 75%;
    margin: auto;
    margin-top: 50px;
    border-bottom: 1px solid;
  }
  small {
    font-size: 11px;
  }
}
</style>
