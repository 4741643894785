<template>
  <div class="home">
    <div class="damen-categories row justify-content-center">
      <div
        class="display col-md-2 align-items-column"
        v-for="(category, index) in damenCategories.child"
        :key="index"
      >
        <div
          class="category container d-flex align-items-center flex-column"
          :id="category.id"
          v-on:mouseenter="showDamenSubCategories(category.id)"
          v-on:mouseleave="hiddenDamenSubCat(category.id)"
        >
          <a :href="'/article/show?categoryId=' + category.id"
            ><img
              :src="
                $store.state.imagesStorage +
                'categories/' +
                category.id +
                '/' +
                category.image
              "
          /></a>
          <h5 class="text-secondary">{{ category.title }}</h5>
          <div class="d-sub-cat d-none" :id="'d-sub-cat' + category.id">
            <div
              class="sub-cat-title"
              v-for="(subCategory, index) in category.categories"
              :key="index"
            >
              <p class="">
                <a :href="'/article/show?categoryId=' + subCategory.id">{{
                  subCategory.title
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="damen d-flex align-items-start flex-column container"
      :style="{
        backgroundImage:
          'url(' +
          $store.state.imagesStorage +
          'categories/' +
          damenCategories.category.id +
          '/' +
          damenCategories.category.image +
          ')',
      }"
    >
      <div class="damen-logo mt-5 d-flex justify-content-center w-100 align-items-end">
        <a href="/fashionWomen">
          <div class="damenmode text-danger">
            <h3 class="title1">WOMEN'S FASHION</h3>
            <p class="text-secondary title2">JUST ALL WHAT YOU NEED</p>
          </div>
        </a>
      </div>
      <div class="damen-schuhe d-flex align-items-end justify-content-center w-100">
        <div class="row display-schuhen p-5">
          <div
            class="schuhen col-md-2 text-center p-3"
            v-for="(category, index) in dammenSchuheCategories.categories"
            :key="index"
          >
            <a :href="'/article/show?categoryId=' + category.id"
              ><img
                class=""
                :src="
                  $store.state.imagesStorage +
                  'categories/' +
                  category.id +
                  '/' +
                  category.image
                "
              />
              <p class="text-secondary">
                {{ category.title }}
              </p>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="herren"
      :style="{
        backgroundImage:
          'url(' +
          $store.state.imagesStorage +
          'categories/' +
          herenCategories.id +
          '/' +
          herenCategories.image +
          ')',
      }"
    >
      <div class="cover"></div>
      <div class="herren-display d-flex justify-content-center align-items-center">
        <div class="herren-categories row">
          <div
            class="herren-category col-md-6 text-center"
            v-for="(category, index) in herenCategories.categories"
            :key="index"
          >
            <a
              :href="'/article/show?categoryId=' + category.id"
              style="text-decoration: none"
              ><img
                class="herren-cat-img"
                :src="
                  $store.state.imagesStorage +
                  'categories/' +
                  category.id +
                  '/' +
                  category.image
                "
              />
              <h5 class="text-secondary text-center m-3">{{ category.title }}</h5></a
            >
          </div>
        </div>
      </div>
      <a href="/fashionMen" style="text-decoration: none">
        <div class="herren-logo text-danger text-center">
          <h3 class="logo-title1">SHOP THE LOOK</h3>
          <h3 class="logo-title2 text-secondary">ALL ABOUT THE GENTELMAN</h3>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      damenCategories: null,
      herenCategories: null,
      dammenSchuheCategories: null,
    };
  },
  methods: {
    showDamenSubCategories(id) {
      $("#d-sub-cat" + id).removeClass("d-none");
    },
    hiddenDamenSubCat(id) {
      $("#d-sub-cat" + id).addClass("d-none");
    },
  },
  mounted() {
    //Get DamenCategories
    this.$store
      .dispatch("getCategories", {
        categoryId: 4,
      })
      .then((res) => {
        this.damenCategories = res.data;
      })
      .catch((err) => {
        console.log(err);
      });

    //Get Damen-Schuen-Categories
    this.$store
      .dispatch("getCategories", {
        categoryId: 11,
      })
      .then((res) => {
        this.dammenSchuheCategories = res.data.category;
      })
      .catch((err) => {
        console.log(err);
      });

    //Get HerrenCategories
    this.$store
      .dispatch("getCategories", {
        categoryId: 5,
      })
      .then((res) => {
        this.herenCategories = res.data.category;
        console.log(this.herenCategories);
      })
      .catch((err) => {
        console.log(err);
      });
  },
  components: {},
  watch: {
    articles: function () {
      if (this.articles != null) {
        this.count = Math.ceil(this.articles.length / 10);
      } else {
        this.count = 0;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
$damenHeight: 700px;
.home {
  .damen-categories {
    .display {
      .category {
        img {
          width: 100px;
          height: 100px;
          border-radius: 100% 100% 100% 100%;
        }
        .d-sub-cat {
          position: absolute;
          z-index: 2;
          background-color: black;
          opacity: 0.7;
          padding: 10px;
          .sub-cat-title {
            p {
              margin-bottom: 0px;
              padding: 1px;
              a {
                color: papayawhip;
              }
            }
          }
        }
      }
    }
  }
  .damen {
    width: 100%;
    min-height: $damenHeight;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .damen-logo {
      min-height: $damenHeight/2;
      a {
        width: 80%;
        background-color: black;
        opacity: 0.7;
        text-align: center;
        text-decoration: none;
        .title1 {
          font-size: 3rem;
          letter-spacing: 1rem;
          border-bottom: 1px solid;
        }
        .title2 {
          font-size: 2rem;
          letter-spacing: 0.6rem;
        }
      }
      a:hover {
        box-shadow: 0 0 5px #ff2121, 0 0 10px #ff2121, 0 0 15px #ff2121;
      }
    }
    .damen-schuhe {
      min-height: $damenHeight/2;
      .display-schuhen {
        .schuhen {
          background-color: black;
          opacity: 0.8;
          a {
            text-decoration: none;

            img {
              width: 120px;
              height: 120px;
              border-radius: 100% 100% 100% 100%;
            }
            p {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
  }
  .herren {
    position: relative;
    margin-top: 20px;
    padding: 50px;
    .cover {
      position: absolute;
      background-color: black;
      opacity: 0.7;
      width: 100%;
      height: 100%;
      left: 0px;
      top: 0px;
    }
    .herren-display {
      width: 100%;
      min-height: 500px;
      .herren-categories {
        .herren-category {
          position: relative;
          .herren-cat-img {
            width: 200px;
            height: 200px;
            margin: -12px;
          }
          h5 {
            letter-spacing: 10px;
          }
        }
      }
    }
    .herren-logo {
      position: relative;
      margin-top: -25px;
      cursor: pointer;
      .logo-title1 {
        letter-spacing: 22px;
      }
      .logo-title2 {
        border-top: 1px solid gray;
        letter-spacing: 10px;
      }
    }
    .herren-logo:hover {
      box-shadow: 0 0 5px #ff2121, 0 0 10px #ff2121, 0 0 15px #ff2121;
    }
  }
}
</style>
