var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-slider"},[_c('div',{staticClass:"articles-slider"},[_c('div',{staticClass:"slider d-flex",style:('width:' + _vm.articles.length * 200 + 'px'),attrs:{"id":_vm.articles[0].category_id}},_vm._l((_vm.articles),function(article,index){return _c('div',{key:index,staticClass:"slider-components",attrs:{"id":"slider-components ms-1"}},[(article.pictures.length > 0)?_c('div',[_c('router-link',{attrs:{"to":{
              path: '/user/article/edit',
              query: {
                id: article.id,
                title: article.title,
                price: article.price,
                description: article.description,
                category_id: article.category_id,
                mainPicture: article.pictures[0].path,
              },
            }}},[_c('img',{staticClass:"card-img-top",staticStyle:{"width":"155px","height":"155px"},attrs:{"src":_vm.$store.state.imagesStorage +
                'articles/' +
                article.id +
                '/' +
                article.pictures[0].path,"alt":"..."}})])],1):_c('div',[_c('router-link',{attrs:{"to":{
              path: '/user/article/edit',
              query: {
                id: article.id,
                title: article.title,
                price: article.price,
                description: article.description,
                category_id: article.category_id,
                mainPicture: null,
              },
            }}},[_c('img',{staticClass:"card-img-top",staticStyle:{"width":"100%","height":"100px"},attrs:{"src":require("@/assets/logo.png"),"alt":"..."}})])],1),_c('div',{staticClass:"card-body"},[_c('small',{staticClass:"card-title text-danger"},[_vm._v(_vm._s(_vm._f("substringTit")(article.title)))]),_c('br'),_c('small',{staticClass:"card-text text-light"},[_vm._v(_vm._s(_vm._f("substringDes")(article.description)))])])])}),0)]),_c('div',{staticClass:"left-icon btn",on:{"click":function($event){return _vm.moveToLeft(_vm.articles[0].category_id)}}},[_c('i',{staticClass:"fa-solid fa-caret-left fa-2xl",staticStyle:{"color":"red"}})]),_c('div',{staticClass:"right-icon btn",on:{"click":function($event){return _vm.moveToRight(_vm.articles[0].category_id)}}},[_c('i',{staticClass:"fa-solid fa-caret-right fa-2xl",staticStyle:{"color":"red"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }