var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fashion-men container mt-5"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header m-auto mb-3 p-5",style:({
        backgroundImage: 'url(' + require('@/assets/menFachion/image.png') + ')',
      })},[_c('div',{staticClass:"cover"}),_vm._m(0),_c('div',{staticClass:"men-categories"},[_c('div',{staticClass:"row"},_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"display-cat col-md-4"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'/article/show?categoryId=' + category.id}},[_c('div',{staticClass:"card text-center p-3"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.$store.state.imagesStorage +
                      'categories/' +
                      category.id +
                      '/' +
                      category.image,"alt":""}})]),_c('h5',{staticClass:"title text-secondary"},[_vm._v(_vm._s(category.title))])])])])}),0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fashion-men-logo text-white d-flex justify-content-center mt-5"},[_c('div',[_c('h3',{staticClass:"logo1"},[_vm._v("SHOP "),_c('span',{staticClass:"text-danger"},[_vm._v("YOUR")]),_vm._v(" LOOK")]),_c('h3',{staticClass:"logo2 text-center"},[_vm._v("JUST "),_c('span',{staticClass:"text-danger"},[_vm._v("4")]),_vm._v(" YOU")])])])
}]

export { render, staticRenderFns }