<template>
  <div class="fachion-women container">
    <div
      class="header m-auto mb-3"
      :style="{
        backgroundImage: 'url(' + require('@/assets/womenFachion/image.png') + ')',
      }"
    >
      <div class="cover"></div>
    </div>
    <div class="row">
      <div
        class="col-md-3 text-center card mb-5 p-1"
        v-for="(category, index) in categories"
        :key="index"
      >
        <a :href="'/article/show?categoryId=' + category.id">
          <div>
            <img
              :src="
                $store.state.imagesStorage +
                'categories/' +
                category.id +
                '/' +
                category.image
              "
            />
            <h5 class="text-center text-secondary">{{ category.title }}</h5>
          </div></a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopmeLaravelVuejsFashion",

  data() {
    return {
      categories: null,
    };
  },

  mounted() {
    this.$store
      .dispatch("getCategoryById", {
        categoryId: 12,
      })
      .then((res) => {
        this.categories = res.data.child;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.fachion-women {
  .header {
    display: block;
    width: 1100px;
    height: 500px;
    position: relative;
    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.5;
    }
  }
  .card {
    img {
      width: 200px;
      height: 200px;
    }
    a {
      text-decoration: none;
    }
  }
}
</style>
