<template>
  <div class="fashion-men container mt-5">
    <div class="content">
      <div
        class="header m-auto mb-3 p-5"
        :style="{
          backgroundImage: 'url(' + require('@/assets/menFachion/image.png') + ')',
        }"
      >
        <div class="cover"></div>
        <div class="fashion-men-logo text-white d-flex justify-content-center mt-5">
          <div>
            <h3 class="logo1">SHOP <span class="text-danger">YOUR</span> LOOK</h3>
            <h3 class="logo2 text-center">JUST <span class="text-danger">4</span> YOU</h3>
          </div>
        </div>
        <div class="men-categories">
          <div class="row">
            <div
              class="display-cat col-md-4"
              v-for="(category, index) in categories"
              :key="index"
            >
              <a
                :href="'/article/show?categoryId=' + category.id"
                style="text-decoration: none"
              >
                <div class="card text-center p-3">
                  <div class="img">
                    <img
                      :src="
                        $store.state.imagesStorage +
                        'categories/' +
                        category.id +
                        '/' +
                        category.image
                      "
                      alt=""
                    />
                  </div>
                  <h5 class="title text-secondary">{{ category.title }}</h5>
                </div></a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShopmeLaravelVuejsMenFachion",

  data() {
    return {
      categories: null,
    };
  },

  mounted() {
    this.$store
      .dispatch("getCategoryById", {
        categoryId: 17,
      })
      .then((res) => {
        console.log(res.data.child);
        this.categories = res.data.child;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.fashion-men {
  .header {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: black;
      opacity: 0.7;
    }
    .fashion-men-logo {
      background-color: black;
      opacity: 0.5;
      border: 2px solid;
      padding: 10px;
      .logo1 {
        letter-spacing: 30px;
        border-bottom: 1px solid;
        font-family: fantasy;
      }
      .logo2 {
        letter-spacing: 10px;
        font-family: fantasy;
      }
    }
    .men-categories {
      .display-cat {
        .card {
          background: transparent;
          img {
            width: 200px;
            height: 200px;
          }
        }
      }
    }
  }
}
</style>
