var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"show-article d-flex align-items-center"},[_c('div',[(_vm.article.pictures.length > 0)?_c('div',{staticClass:"image d-flex justify-content-center"},[_c('div',[_c('img',{staticClass:"show-img",attrs:{"src":_vm.imagesStorage + 'articles/' + _vm.article.id + '/' + _vm.article.pictures[0].path,"alt":"No-pic"}})])]):_vm._e(),_c('div',{staticClass:"header d-flex justify-content-center"},[_c('div',{staticClass:"content"},[_c('h5',{staticClass:"header-title"},[_vm._v(_vm._s(_vm._f("substringTit")(_vm.article.title)))]),_c('p',{staticClass:"header-price text-primary mb-0"},[_vm._v(_vm._s(_vm.article.price)+"€")])])]),_c('div',{staticClass:"body d-flex justify-content-center"},[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm._f("substringDes")(_vm.article.description))+"...")])]),_c('div',{staticClass:"footer"},[_c('div',[(_vm.isAdmin)?_c('div',[(_vm.article.pictures.length > 0)?_c('div',[_c('router-link',{staticClass:"btn btn-danger w-100",attrs:{"to":{
                path: '/admin/article/edit',
                query: {
                  id: _vm.article.id,
                  title: _vm.article.title,
                  price: _vm.article.price,
                  description: _vm.article.description,
                  category_id: _vm.article.category_id,
                  mainPicture: _vm.article.pictures[0].path,
                },
              }}},[_vm._v(" show")])],1):_c('div',[_c('router-link',{staticClass:"btn btn-danger w-100",attrs:{"to":{
                path: '/admin/article/edit',
                query: {
                  id: _vm.article.id,
                  title: _vm.article.title,
                  price: _vm.article.price,
                  description: _vm.article.description,
                  category_id: _vm.article.category_id,
                },
              }}},[_vm._v(" show")])],1)]):_c('div',[(_vm.article.pictures.length > 0)?_c('div',[_c('router-link',{staticClass:"btn btn-danger w-100",attrs:{"to":{
                path: '/user/article/edit',
                query: {
                  id: _vm.article.id,
                  title: _vm.article.title,
                  price: _vm.article.price,
                  description: _vm.article.description,
                  category_id: _vm.article.category_id,
                  mainPicture: _vm.article.pictures[0].path,
                },
              }}},[_vm._v(" show")])],1):_c('div',[_c('router-link',{staticClass:"btn btn-danger w-100",attrs:{"to":{
                path: '/user/article/edit',
                query: {
                  id: _vm.article.id,
                  title: _vm.article.title,
                  price: _vm.article.price,
                  description: _vm.article.description,
                  category_id: _vm.article.category_id,
                },
              }}},[_vm._v(" show")])],1),_c('a',{staticClass:"btn btn-outline-danger w-100 mt-1",on:{"click":function($event){return _vm.addToCart(_vm.article, $event)}}},[_vm._v("Add to Cart")])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }