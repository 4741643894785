<template>
  <nav aria-label="Page navigation example">
    <ul class="pagination">
      <li class="page-item">
        <a class="page-link" v-on:click="left()" aria-label="Previous">
          <span aria-hidden="true">&laquo;</span>
        </a>
      </li>
      <div class="content">
        <div class="content-item d-flex">
          <div v-for="n in count" :key="n">
            <li class="page-item" style="width: 50px">
              <a class="page-link btn" v-on:click="paginationBtn(n)">{{ n }}</a>
            </li>
          </div>
        </div>
      </div>

      <li class="page-item">
        <a class="page-link" v-on:click="right()" aria-label="Next">
          <span aria-hidden="true">&raquo;</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import $ from "jquery";
export default {
  data: function () {
    return {
      pageNr: null,
      position: 0,
      positionCount: 3,
    };
  },
  methods: {
    paginationBtn: function (n) {
      this.$emit("changePageNr", n);
    },
    left() {
      if (this.positionCount < this.count) {
        this.positionCount += 3;
        console.log("Caount:" + this.count);
        console.log("positionCount:" + this.positionCount);
        this.position = this.position - 150;
        $(".content-item").css("left", this.position);
      }
    },
    right() {
      if (this.position < 0) {
        this.positionCount -= 3;
        console.log("Count:" + this.count);
        console.log("positionCount:" + this.positionCount);
        this.position = this.position + 150;
        $(".content-item").css("left", this.position);
      }
    },
  },
  props: ["count"],
};
</script>

<style lang="scss" scoped>
.pagination {
  .content {
    width: 150px;
    height: 100px;
    overflow: hidden;
    position: relative;
    .content-item {
      position: absolute;
    }
  }
}
</style>
