<template>
  <div class="navbar" v-on:click="removeSearchedArticle()">
    <div
      class="nav"
      :style="{ backgroundImage: 'url(' + require('@/assets/logo2.png') + ')' }"
    >
      <div class="cover"></div>

      <div class="header ps-5 pt-5 w-100 row text-secondary">
        <div class="items col-md-6">
          <a class="text-secondary" href="/"
            ><h4 class="item d-md-inline-block ms-3">
              <img src="@/assets/logo.png" /></h4
          ></a>
          <a class="text-secondary" href="/article/show?categoryId=4"
            ><h4 class="item d-md-inline-block ms-3">Damen</h4></a
          >
          <a class="text-secondary" href="/article/show?categoryId=5"
            ><h4 class="item d-md-inline-block ms-3">Herren</h4></a
          >
          <a class="text-secondary" href="/article/show?categoryId=6"
            ><h4 class="item d-md-inline-block ms-3">Mädchen</h4></a
          >
          <a class="text-secondary" href="/article/show?categoryId=7"
            ><h4 class="item d-md-inline-block ms-3">Jungen</h4></a
          >
          <div v-if="navbar != 'AdminNav'" class="item d-md-inline-block ms-3">
            <div id="count-articles-inCart" class="count-articles-inCart text-danger">
              {{ countArticlesInCart }}
            </div>
            <a class="text-secondary" href="/cart"
              ><h4 style="margin-bottom: 0px">
                <i class="fa fa-shopping-cart" aria-hidden="true"></i></h4
            ></a>
          </div>
        </div>
        <div class="search col-md-6 d-flex justify-content-end">
          <div class="search-article row w-50">
            <input
              v-model="search"
              v-on:keypress="getResult()"
              type="text"
              class="form-control me-3"
              placeholder="Search"
              aria-label="Username"
              aria-describedby="basic-addon1"
              style="display: block"
            />
            <i
              class="fa fa-search"
              aria-hidden="true"
              style="margin-top: -25px; margin-left: 246px; width: 20px"
            ></i>
            <div class="searched-articles">
              <SearchedArticles v-if="showResult" v-bind:articles="articles" />
            </div>
          </div>
          <div class="log-in-out">
            <div v-if="navbar == 'GlobalNav'" class="log-in">
              <h4><a href="/auth/login" class="text-secondary">Login</a></h4>
            </div>
            <div v-else-if="navbar == 'AdminNav'" class="log-out">
              <h4 v-on:click="logout()">Logout</h4>
            </div>
          </div>
          <div
            class="nav-item dropdown text-danger"
            v-if="navbar == 'CustomerNav'"
            style="height: 33px"
          >
            <a
              class="nav-link dropdown-toggle btn text-danger"
              id="user-dropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="fa fa-user" aria-hidden="true"></i>
            </a>
            <ul
              class="dropdown-menu bg-dark"
              aria-labelledby="navbarDropdown"
              style="left: -117px"
            >
              <li>
                <a class="dropdown-item text-secondary" href="/user/deliveryAddress"
                  >Delivery Addresses</a
                >
              </li>
              <li>
                <a class="dropdown-item text-secondary" href="/user/invoice/show"
                  >Invoicess & Orders</a
                >
              </li>
              <li><hr class="dropdown-divider" /></li>
              <li>
                <a class="dropdown-item btn text-danger" v-on:click="logout()">Logout</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="admin-nav" v-if="navbar == 'AdminNav'">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-secondary"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Category
                </a>
                <ul class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item text-danger" href="/admin/category/create"
                      >Create</a
                    >
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item text-danger" href="/admin/category/show"
                      >Show</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-secondary"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Article
                </a>
                <ul class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item text-danger" href="/admin/article/create"
                      >Create</a
                    >
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li>
                    <a class="dropdown-item text-danger" href="/admin/article/show"
                      >Show</a
                    >
                  </li>
                </ul>
              </li>
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle text-secondary"
                  href="#"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Invoices&&Orders
                </a>
                <ul class="dropdown-menu bg-dark" aria-labelledby="navbarDropdown">
                  <li>
                    <a class="dropdown-item text-danger" href="/admin/invoice/show"
                      >Show Invoices</a
                    >
                  </li>
                  <li><hr class="dropdown-divider" /></li>
                  <li><a class="dropdown-item text-danger" href="#">Show Orders</a></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
    <div class="nav-logo-content">
      <h2>
        <p class="p1" style="left: 100px">S</p>
        <p class="p2" style="left: 200px">H</p>
        <p class="p3" style="left: 300px">O</p>
        <p class="p4" style="left: 400px">P</p>
        <p class="p5" style="left: 500px">M</p>
        <p class="p6" style="left: 600px">E</p>
      </h2>
    </div>
    <h2 class="logo-teil2">ALL WHAT YOU NEED</h2>
    <span class="light"></span>
  </div>
</template>

<script>
import SearchedArticles from "@/components/Article/SearchedArticles.vue";

export default {
  data: function () {
    return {
      navbar: null,
      search: null,
      searchedValues: [],
      articles: null,
      showResult: false,
      countArticlesInCart: JSON.parse(localStorage.getItem("articlesInCart")).length,
    };
  },
  methods: {
    removeSearchedArticle() {
      this.showResult = false;
    },
    getResult() {
      console.log(this.search);
      this.setResult();
      this.searchedValues = this.search.split(" ");
      this.$store
        .dispatch("getSearchedArticles", {
          searchedValues: this.searchedValues.reverse(),
        })
        .then((res) => {
          this.articles = res.data.articles;
          console.log("Result:");
          console.log(res.data.articles);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setResult() {
      this.showResult = false;
      if (this.search != "") {
        this.showResult = true;
      }
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then((res) => {
          if (res.data.success == true) {
            if (this.$route.name == "Home") {
              window.location.reload();
            } else {
              this.$router.push({ name: "Home" });
              window.location.reload();
            }
          } else {
            if (this.$route.name != "ErrorPage") {
              this.$router.push({ name: "ErrorPage" });
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  components: {
    SearchedArticles,
  },
  created() {
    var refreshToken = localStorage.getItem("refreshToken");
    var roles = JSON.parse(localStorage.getItem("roles"));
    if (refreshToken == null || roles == null) {
      this.navbar = "GlobalNav";
    } else {
      if (roles.some((role) => role.title === "CUSTOMER")) {
        this.navbar = "CustomerNav";
      } else if (roles.some((data) => data.title === "ADMIN")) {
        this.navbar = "AdminNav";
      } else {
        this.navbar = "GlobalNav";
      }
    }
    console.log(this.navbar);
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  .nav {
    width: 100%;
    min-height: 300px;
    background-position: center;
    position: relative;
    .cover {
      position: absolute;
      background-color: black;
      opacity: 0.8;
      left: 0px;
      top: 0px;
      width: 100%;
      height: 100%;
    }
    .count-articles-inCart {
      margin-bottom: -8px;
      margin-left: 10px;
    }
    .header {
      position: relative;
      .items {
        img {
          width: 50px;
          height: 50px;
          border-radius: 100% 100% 100% 100%;
          margin-top: -28px;
        }
        .item {
          border-bottom: 1px solid red;
          cursor: pointer;
          a {
            text-decoration: none;
          }
        }
        .item:hover {
          transform: scale(0.7);
        }
      }
      .search {
        .search-article {
          border-bottom: 3px solid red;
          height: 31px;
          margin-right: 12px;
          i {
            width: 0px;
          }
          input {
            background: none;
            border: none;
            color: gray;
          }
          ::placeholder {
            color: gray;
          }
        }
        h4 {
          border-bottom: 1px solid red;
          cursor: pointer;
          a {
            text-decoration: none;
          }
        }
      }
    }
  }
  .nav-logo-content,
  .logo-teil2,
  .light {
    display: none;
    font-family: fantasy;
  }
}
.logo {
  border-radius: 100% 100% 100% 100%;
}
.logo-image {
  height: 44px;
  width: 44px;
}

@media screen and (min-width: 900px) {
  .navbar {
    .admin-nav {
      position: absolute;
      top: 28%;
      left: 4%;
    }
    .nav-logo-content {
      display: block;
      position: absolute;
      color: red;
      opacity: 0.5;
      z-index: 2;
      left: 10%;
      h2 {
        font-size: 50px;
        animation: fontSize 1s ease both 2.1s;
        p {
          display: inline-block;
          position: absolute;
          transform: rotateY(90deg);
        }
        .p1 {
          animation: rotate 1s ease both 1.4s;
        }
        .p2 {
          animation: rotate 1s ease both 1.6s;
        }
        .p3 {
          animation: rotate 1s ease both 1.8s;
        }
        .p4 {
          animation: rotate 1s ease both 2s;
        }
        .p5 {
          animation: rotate 1s ease both 2.2s;
        }
        .p6 {
          animation: rotate 1s ease both 2.4s;
        }
      }
    }

    .logo-teil2 {
      display: block;
      position: absolute;
      top: 54%;
      left: 64%;
      font-size: 30px;
      padding: 10px;
      color: rgba(134, 131, 131, 0.8);
      background: black;
      opacity: 0.4;
      filter: blur(4px);
      animation: blur 3s ease 2.5s both;
    }
    .light {
      visibility: visible;
      display: inline-block;
      visibility: hidden;
      width: 100%;
      height: 2px;
      border: 1px solid;
      position: absolute;
      top: 60%;
      left: -100%;
      background: linear-gradient(90deg, transparent, red);
      animation: move 4s ease 0.8s both;
      box-shadow: 0 0px 10px #ff2121, 0 0px 40px #ff2121, 0 0px 80px #ff2121;
    }
  }
  @keyframes rotate {
    from {
      transform: rotateY(90deg);
      filter: blur(4px);
    }
    to {
      transform: rotateY(0deg);
      filter: blur(0);
    }
  }
  @keyframes move {
    from {
      left: -100%;
      visibility: hidden;
    }
    to {
      left: 100%;
      visibility: visible;
      width: 0px;
    }
  }
  @keyframes fontSize {
    from {
      font-size: 50px;
    }
    to {
      font-size: 70px;
    }
  }
  @keyframes blur {
    from {
      filter: blur(4px);
    }
    to {
      filter: blur(0px);
    }
  }
}
</style>
