var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('div',{staticClass:"damen-categories row justify-content-center"},_vm._l((_vm.damenCategories.child),function(category,index){return _c('div',{key:index,staticClass:"display col-md-2 align-items-column"},[_c('div',{staticClass:"category container d-flex align-items-center flex-column",attrs:{"id":category.id},on:{"mouseenter":function($event){return _vm.showDamenSubCategories(category.id)},"mouseleave":function($event){return _vm.hiddenDamenSubCat(category.id)}}},[_c('a',{attrs:{"href":'/article/show?categoryId=' + category.id}},[_c('img',{attrs:{"src":_vm.$store.state.imagesStorage +
              'categories/' +
              category.id +
              '/' +
              category.image}})]),_c('h5',{staticClass:"text-secondary"},[_vm._v(_vm._s(category.title))]),_c('div',{staticClass:"d-sub-cat d-none",attrs:{"id":'d-sub-cat' + category.id}},_vm._l((category.categories),function(subCategory,index){return _c('div',{key:index,staticClass:"sub-cat-title"},[_c('p',{},[_c('a',{attrs:{"href":'/article/show?categoryId=' + subCategory.id}},[_vm._v(_vm._s(subCategory.title))])])])}),0)])])}),0),_c('div',{staticClass:"damen d-flex align-items-start flex-column container",style:({
      backgroundImage:
        'url(' +
        _vm.$store.state.imagesStorage +
        'categories/' +
        _vm.damenCategories.category.id +
        '/' +
        _vm.damenCategories.category.image +
        ')',
    })},[_vm._m(0),_c('div',{staticClass:"damen-schuhe d-flex align-items-end justify-content-center w-100"},[_c('div',{staticClass:"row display-schuhen p-5"},_vm._l((_vm.dammenSchuheCategories.categories),function(category,index){return _c('div',{key:index,staticClass:"schuhen col-md-2 text-center p-3"},[_c('a',{attrs:{"href":'/article/show?categoryId=' + category.id}},[_c('img',{attrs:{"src":_vm.$store.state.imagesStorage +
                'categories/' +
                category.id +
                '/' +
                category.image}}),_c('p',{staticClass:"text-secondary"},[_vm._v(" "+_vm._s(category.title)+" ")])])])}),0)])]),_c('div',{staticClass:"herren",style:({
      backgroundImage:
        'url(' +
        _vm.$store.state.imagesStorage +
        'categories/' +
        _vm.herenCategories.id +
        '/' +
        _vm.herenCategories.image +
        ')',
    })},[_c('div',{staticClass:"cover"}),_c('div',{staticClass:"herren-display d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"herren-categories row"},_vm._l((_vm.herenCategories.categories),function(category,index){return _c('div',{key:index,staticClass:"herren-category col-md-6 text-center"},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":'/article/show?categoryId=' + category.id}},[_c('img',{staticClass:"herren-cat-img",attrs:{"src":_vm.$store.state.imagesStorage +
                'categories/' +
                category.id +
                '/' +
                category.image}}),_c('h5',{staticClass:"text-secondary text-center m-3"},[_vm._v(_vm._s(category.title))])])])}),0)]),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"damen-logo mt-5 d-flex justify-content-center w-100 align-items-end"},[_c('a',{attrs:{"href":"/fashionWomen"}},[_c('div',{staticClass:"damenmode text-danger"},[_c('h3',{staticClass:"title1"},[_vm._v("WOMEN'S FASHION")]),_c('p',{staticClass:"text-secondary title2"},[_vm._v("JUST ALL WHAT YOU NEED")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"/fashionMen"}},[_c('div',{staticClass:"herren-logo text-danger text-center"},[_c('h3',{staticClass:"logo-title1"},[_vm._v("SHOP THE LOOK")]),_c('h3',{staticClass:"logo-title2 text-secondary"},[_vm._v("ALL ABOUT THE GENTELMAN")])])])
}]

export { render, staticRenderFns }