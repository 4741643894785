var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fachion-women container"},[_c('div',{staticClass:"header m-auto mb-3",style:({
      backgroundImage: 'url(' + require('@/assets/womenFachion/image.png') + ')',
    })},[_c('div',{staticClass:"cover"})]),_c('div',{staticClass:"row"},_vm._l((_vm.categories),function(category,index){return _c('div',{key:index,staticClass:"col-md-3 text-center card mb-5 p-1"},[_c('a',{attrs:{"href":'/article/show?categoryId=' + category.id}},[_c('div',[_c('img',{attrs:{"src":_vm.$store.state.imagesStorage +
              'categories/' +
              category.id +
              '/' +
              category.image}}),_c('h5',{staticClass:"text-center text-secondary"},[_vm._v(_vm._s(category.title))])])])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }