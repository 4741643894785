<template>
  <div>
    <EditArticle v-bind:article="article" /> 
    <div class="text-center">
      <button
        class="btn btn-danger w-50 mb-5"
        v-on:click="addToCart(articleFromDB, $event)"
      >
        Add to Cart
      </button>
    </div>
  </div>
</template>

<script>
import EditArticle from "@/components/Article/EditArticle";
import $ from "jquery";
export default {
  data() {
    return {
      article: null,
      articleFromDB: null,
    };
  },

  created() {
    this.article = this.$route.query;
    this.$store
      .dispatch("getArticleById", {
        articleId: this.article.id,
      })
      .then((res) => {
        this.articleFromDB = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
 
  methods: {
    addToCart(article, e) {
      var articles = [];
      //localStorage.removeItem("articlesInCart");
      if (localStorage.getItem("articlesInCart") == null) {
        articles.push(article);
        localStorage.setItem("articlesInCart", JSON.stringify(articles));
      } else {
        JSON.parse(localStorage.getItem("articlesInCart")).forEach((articleInCart) => {
          articles.push(articleInCart);
        });
        articles.push(article);
        localStorage.setItem("articlesInCart", JSON.stringify(articles));
      }
      //Add Animation to Button 'addToCart'
      $(e.target).addClass("add-to-cart");
      setTimeout(() => $(e.target).removeClass("add-to-cart"), 2000);
      //Increment Count of Articles in CartSympol in NavBar
      $("#count-articles-inCart").addClass("inc-count-articles");
      setTimeout(
        () => $("#count-articles-inCart").removeClass("inc-count-articles"),
        1000
      );
      $("#count-articles-inCart").text("");
      $("#count-articles-inCart").text(
        JSON.parse(localStorage.getItem("articlesInCart")).length
      );
    },
  },
  components: {
    EditArticle,
  },
};
</script>
