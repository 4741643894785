import { render, staticRenderFns } from "./ShowArticles.vue?vue&type=template&id=704b57d5&scoped=true"
import script from "./ShowArticles.vue?vue&type=script&lang=js"
export * from "./ShowArticles.vue?vue&type=script&lang=js"
import style0 from "./ShowArticles.vue?vue&type=style&index=0&id=704b57d5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "704b57d5",
  null
  
)

export default component.exports