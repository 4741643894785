import { render, staticRenderFns } from "./GlobalNav.vue?vue&type=template&id=2b4e90ec&scoped=true"
import script from "./GlobalNav.vue?vue&type=script&lang=js"
export * from "./GlobalNav.vue?vue&type=script&lang=js"
import style0 from "./GlobalNav.vue?vue&type=style&index=0&id=2b4e90ec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b4e90ec",
  null
  
)

export default component.exports