<template>
  <div>
    <global-nav></global-nav>
    <router-view />
    <footer-vue></footer-vue>
  </div>
</template>
<script>
import GlobalNav from "@/components/Navbar/GlobalNav.vue";
import FooterVue from "./components/Footer/Footer.vue";

export default {
  data: function () {
    return {};
  },

  components: {
    GlobalNav,
    FooterVue,
  },
  computed: {
    choseNavbar() {
      //localStorage.removeItem("refreshToken");localStorage.removeItem("roles");
      console.log(
        "refresh_token:" +
          localStorage.getItem("refreshToken") +
          ". Roles:" +
          localStorage.getItem("roles")
      );
      var refreshToken = localStorage.getItem("refreshToken");
      var roles = JSON.parse(localStorage.getItem("roles"));
      /*if (refreshToken == null || roles == null) {
        return GlobalNav;
      } else {
        if (roles.some((role) => role.title === "CUSTOMER")) {
          return CustomerNav;
        } else if (roles.some((data) => data.title === "ADMIN")) {
          return AdminNav
        } else {
          return GlobalNav;
        }
      }*/
    },
  },
};
</script>
